import {
  addHours,
  differenceInMonths,
  format,
  formatDistanceStrict,
  intlFormat,
  isAfter,
  isSameDay,
  isValid,
  parseISO,
} from 'date-fns';
import { enUS, es, pt } from 'date-fns/locale';

import { configValue } from 'config/appConfigUtils';
import { IDEALIST_TIME_ZONE } from 'utils/constants/general/idealistTimeZone';

export const LOCALES = {
  en: enUS,
  es,
  pt,
};

export function getDate(date: string | number | Date) {
  return typeof date === 'string' ? parseISO(date) : date;
}

function formatTime(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locale: any,
  params: Parameters<typeof Intl.DateTimeFormat>[1] = Object.freeze({}),
) {
  const { timeZone = undefined, ...spreadParams } = params;
  return new Date(date).toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    timeZone: timeZone || undefined,
    ...spreadParams,
  });
}

const SHORTER_DATE_FORMAT = {
  en: 'M/d/yyyy',
  es: 'd/M/yyyy',
  pt: 'd/M/yyyy',
};

export function shorterDate(date: string | number | Date) {
  return format(getDate(date), SHORTER_DATE_FORMAT[CURRENT_LOCALE]);
}

const SHORTEST_DATE_FORMAT = {
  en: 'M/d',
  es: 'd/M',
  pt: 'd/M',
};

export function shortestDate(date: string | number | Date) {
  return format(getDate(date), SHORTEST_DATE_FORMAT[CURRENT_LOCALE]);
}

const SHORTEST_DATE_WITH_YEAR_FORMAT = {
  en: 'M/d/yy',
  es: 'd/M/yy',
  pt: 'd/M/yy',
};

export function shortestDateWithYear(date: string | number | Date) {
  return format(getDate(date), SHORTEST_DATE_WITH_YEAR_FORMAT[CURRENT_LOCALE]);
}

export function shorterTime(
  date: string | number | Date,
  timezone: string | null | undefined,
  displayTimezone?: boolean,
) {
  const options: Parameters<typeof Intl.DateTimeFormat>[1] = {
    timeZoneName: displayTimezone ? 'short' : undefined,
    timeZone: timezone || undefined,
  };
  return formatTime(date, LOCALES[CURRENT_LOCALE].code, options);
}

const DATETIME_FORMAT = {
  en: 'MM/dd/yyyy  hh:mma',
  es: 'dd/MM/yyyy HH:mm',
  pt: 'dd/MM/yyyy HH:mm',
};

export function dateTime(date: string | number | Date) {
  return format(getDate(date), DATETIME_FORMAT[CURRENT_LOCALE]);
}

const SHORT_DATETIME_FORMAT = {
  en: 'M/d/yy h:mma',
  es: 'd/M/yy HH:mm',
  pt: 'd/M/yy HH:mm',
};

export function shortDateTime(date: string | number | Date) {
  return format(getDate(date), SHORT_DATETIME_FORMAT[CURRENT_LOCALE]);
}

export function humanDate(
  date: string | number | Date,
  timeZone?: string | null,
  opts: {
    shortMonth?: boolean;
  } = {},
) {
  const parsedDate = getDate(date);
  if (!isValid(parsedDate)) return 'Invalid Date';

  return intlFormat(
    parsedDate,
    {
      year: 'numeric',
      month: opts && opts.shortMonth ? 'short' : 'long',
      day: 'numeric',
      timeZone: timeZone || undefined,
    },
    {
      locale: LOCALES[CURRENT_LOCALE].code,
    },
  );
}

const HUMAN_DATETIME_FORMAT = {
  en: 'MMM d, yyyy h:mm a',
  es: 'MMM d, yyyy H:mm',
  pt: 'MMM d, yyyy H:mm',
};

export function humanDateTime(date: string | number | Date) {
  return format(getDate(date), HUMAN_DATETIME_FORMAT[CURRENT_LOCALE], {
    locale: LOCALES[CURRENT_LOCALE],
  });
}

export function monthAndYear(date: string | number | Date) {
  return format(getDate(date), 'MMMM yyyy', {
    locale: LOCALES[CURRENT_LOCALE],
  });
}

export function dayAndMonth(date: string | number | Date, longMonth?: boolean) {
  return format(getDate(date), longMonth ? 'MMMM d' : 'MMM d', {
    locale: LOCALES[CURRENT_LOCALE],
  });
}

export function dowDayAndMonth(date: string | number | Date) {
  return format(getDate(date), 'cccc, MMMM d', {
    locale: LOCALES[CURRENT_LOCALE],
  });
}

export function dayNumber(date: string | number | Date) {
  return format(getDate(date), 'd', {
    locale: LOCALES[CURRENT_LOCALE],
  });
}

export function shortMonth(date: string | number | Date) {
  return format(getDate(date), 'MMM', {
    locale: LOCALES[CURRENT_LOCALE],
  }).slice(0, 3);
}

export function yearOnly(date: string | number | Date) {
  return format(getDate(date), 'yyyy', {
    locale: LOCALES[CURRENT_LOCALE],
  });
}

export function shortMonthAndYear(date: string | number | Date) {
  return format(getDate(date), 'MMM yyyy', {
    locale: LOCALES[CURRENT_LOCALE],
  });
}

export function now() {
  const dtOverride = configValue('idealist', 'dtOverride');

  if (dtOverride) {
    return parseISO(dtOverride);
  }

  return new Date();
}

export function daysInMs(days: number) {
  return 1000 * 60 * 60 * 24 * days;
}

export function distanceInWordsToNow(date: string | number | Date) {
  return formatDistanceStrict(getDate(date), now(), {
    addSuffix: true,
    locale: LOCALES[CURRENT_LOCALE],
    roundingMethod: 'floor',
  });
}

export function distanceInYearsAndMonthsAbbr(
  start: string | number | Date,
  end: string | number | Date,
) {
  const distance = differenceInMonths(getDate(start), getDate(end));

  if (!distance) {
    return null;
  }

  const years = Math.floor(distance / 12);
  const months = distance - years * 12;
  return [years, months]
    .map((amt, index) =>
      amt === 0
        ? null
        : `${amt} ${index === 0 ? 'yr' : 'mo'}${amt > 1 ? 's' : ''}`,
    )
    .filter((amt) => amt)
    .join(', ');
}

function hasSeconds(time: string) {
  return time.split(':').length === 3;
}

export function withSeconds(time: string) {
  return hasSeconds(time) ? time : `${time}:00`;
}

// Converts a date or date string to GMT timezone
export function toGMTDate(date: string | Date) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-param-reassign
  date = new Date(date);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function stripTimezone(dateString: string) {
  return dateString.replace('Z', '');
}

// Take a timezone-aware date and return the user's date / time (not timezone-aware)
// as a string
export function formatLocalDate(d: Date): string {
  const convertedDate = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
  return stripTimezone(convertedDate.toISOString());
}

export function formatIdealistDateTime(d: Date | string): Date {
  // XXX alternative implementation of this function could be this, but would need QA:
  // export const formatIdealistDateTime = (d: Date | string): Date => {
  //   new Date(d.toLocaleString('en-US', { timeZone: IDEALIST_TIME_ZONE }));
  const options = {
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: IDEALIST_TIME_ZONE,
  };
  const formattedDateParts: Array<{
    type: string;
    value: string;
    // @ts-expect-error TS(2345): Argument of type '{ hour12: boolean; year: string;... Remove this comment to see the full error message
  }> = new Intl.DateTimeFormat('en-us', options).formatToParts(d);
  const dateTimeParams = formattedDateParts
    .filter((datePart) =>
      ['year', 'month', 'day', 'hour', 'minute', 'second'].includes(
        datePart.type,
      ),
    )
    .map((datePart) => parseInt(datePart.value, 10));
  const [month, day, year, hour, minute, second] = dateTimeParams;
  return new Date(year, month - 1, day, hour === 24 ? 0 : hour, minute, second);
}

function getLocalizedToday(date: string | Date) {
  const offsetMatch =
    typeof date === 'string'
      ? date.match(/([+-]?[0-9]+)(?::[0-9]+)?$/)
      : date.getTimezoneOffset();
  const offsetToDate =
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-nested-ternary
    typeof offsetMatch === 'number'
      ? offsetMatch
      : offsetMatch
        ? // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          parseInt(offsetMatch.pop(), 10)
        : 0;
  const today = now();
  const offsetFromNow = today.getTimezoneOffset() / 60;
  const offsetTotal = offsetToDate + offsetFromNow;
  return addHours(today, offsetTotal);
}

export function dateIsToday(date: string | Date) {
  return isSameDay(getLocalizedToday(date), getDate(date));
}

export function dateIsUpcoming(date: string | Date) {
  const today = getLocalizedToday(date);
  return isAfter(getDate(date), today) || isSameDay(today, getDate(date));
}

export function dateIsPast(date: string | Date) {
  return !dateIsUpcoming(date);
}

export function getCurrentYear() {
  return now().getFullYear();
}
